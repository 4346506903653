html {
    background-color: black;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding-top: 190px;
    /* background-color: #fffdf6; */
    background-color: white;
}

body.splash {
    padding-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    /*  text-transform: uppercase; */
    font-weight: 700;
}

button, [type="button"], [type="submit"] {
    cursor: pointer;
}

.s-alert-success {
    background: #18BC9C;
    opacity: 0.7;
}

.modal-header, .modal-content {
    border-radius: 10px;
}

.modal-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* Prevent header and footer from jumping around when a modal is opened or */
/* closed. */
body {
    overflow-y: scroll;
}
body.modal-open .fixed-top,
body.modal-open .fixed-bottom {
    right: 15px !important;
}

.max-width-content {
    max-width: initial;
    max-width: max-content;
}

.alternateemail {
    display: none !important;
}
