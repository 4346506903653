/* add css module styles here (optional) */

._3d7Pq {
    padding: 0;
    vertical-align: unset;
}

._21FCP {
    line-height: 1;
}

._38hvL {
    display: block;
    font-size: 12px;
    min-height: 12px;
    line-height: 12px;
    margin: 5px;
}
